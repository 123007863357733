<template>
  <div>
    <v-form>
      <p class="mb-3">
        Fill in the table below. Draw the perspective diagram of each species showing the
        VSEPR-predicted molecular shape.
      </p>
      <v-simple-table>
        <thead>
          <tr>
            <th>Species</th>
            <th>Perspective Diagram</th>
            <th>Is the species polar? Justify.</th>
            <th>What is the maximum number of atoms that lie in the same plane? Justify.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex :content="speciesOptionsShuffled[0]" />
            </td>
            <td>
              <v-file-input v-model="attachmentsPart1" multiple />
            </td>
            <td>
              <s-textarea
                v-model="inputs.isPolarQuestion1"
                outlined
                :counter="true"
                :maxlength="1200"
              />
            </td>
            <td>
              <s-textarea
                v-model="inputs.maxAtomsQuestion1"
                outlined
                :counter="true"
                :maxlength="1200"
              />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex :content="speciesOptionsShuffled[1]" />
            </td>
            <td>
              <v-file-input v-model="attachmentsPart2" multiple />
            </td>
            <td>
              <s-textarea
                v-model="inputs.isPolarQuestion2"
                outlined
                :counter="true"
                :maxlength="1200"
              />
            </td>
            <td>
              <s-textarea
                v-model="inputs.maxAtomsQuestion2"
                outlined
                :counter="true"
                :maxlength="1200"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUBC_SB_Quiz1_5',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        isPolarQuestion1: null,
        isPolarQuestion2: null,
        maxAtomsQuestion1: null,
        maxAtomsQuestion2: null,
      },
      attachmentsPart1: [],
      attachmentsPart2: [],
    };
  },
  computed: {
    attachments() {
      return [this.attachmentsPart1, this.attachmentsPart2];
    },
    randomNumber() {
      return this.taskState.getValueBySymbol('randomNumber').content;
    },
    seed() {
      return this.$gate.user?.id || 1;
    },
    speciesOptionsShuffled() {
      let speciesOptions1 = ['$\\ce{ XeOF4 }$', '$\\ce{ SeCl4 }$'];
      let speciesOptions2 = ['$\\ce{ BrOF3 }$', '$\\ce{ TeCl4 }$'];
      let speciesOptions3 = ['$\\ce{ SF2O }$', '$\\ce{ XeF4 }$'];
      let speciesOptions4 = ['$\\ce{ XeO3 }$', '$\\ce{ SF6 }$'];
      let speciesOptions5 = ['$\\ce{ SF4 }$', '$\\ce{ PCl5 }$'];

      let options = [
        speciesOptions1,
        speciesOptions2,
        speciesOptions3,
        speciesOptions4,
        speciesOptions5,
      ];

      return options[this.randomNumber.number - 1];
    },
  },
};
</script>
